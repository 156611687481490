import React from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';

const CustomNavbar = () => {
    return (
        <>
            <Navbar bg="light" expand="lg" fixed="top" className="navbar navbar-expand-lg navbar-light bg-white border-bottom box-shadow fixed-top" tabIndex="0">
                <Container>
                    <Navbar.Brand href="/">
                        <Image src="/img/logo3.png" height="30" className="bi bi-layers-half top-0" alt="Logo" />
                        <span className="ms-1 fw-bolder">MIBY</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarSupportedContent" />
                    <Navbar.Collapse id="navbarSupportedContent">
                        <Nav className="me-auto mb-2 mb-lg-0">
                            <Nav.Link href="/">首頁</Nav.Link>                    
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>   
       </>
    )
}

export default CustomNavbar;